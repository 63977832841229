<template>
  <div id="fixed-fields">
    <div class="title">Mobil Uygulama Kartlar</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Mobil uygulama</el-breadcrumb-item>
        <el-breadcrumb-item>Kartlar</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="content-wrapper">
      <div class="form-section">
        <div class="part">
          <el-tabs v-model="activeTab" @tab-click="handleTabClick">
            <el-tab-pane v-for="type in commentTypes" :key="type.ID" :label="type.Name" :name="type.ID.toString()">
              <div class="header">Kartlar ({{ HomeInformationCards.length }})</div>

              <div v-for="(item, index) in HomeInformationCards" :key="index" class="card-container">
                <div class="card-edit-section">
                  <div class="top">
                    <div class="mid-text">Kart {{ index + 1 }}</div>
                  </div>
                  
                  <div class="images-container">
                    <!-- Web Image -->
                    <div class="image-wrapper">
                      <div class="image-title">Web Image</div>
                      <input type="file" @change="uploadFile($event, index, 'web')" style="display: none" :ref="'file_web_' + index" />
                      <div v-if="!item.ImageUrl" class="image upload-button" @click="uploadFiles(index, 'web')">
                        <i class="icon-camera-2"></i>
                        <div>Fotoğraf Yükle</div>
                      </div>
                      <div v-else class="image image-preview" :style="{ backgroundImage: 'url(' + item.ImageUrl + ')' }">
                        <div class="image-overlay">
                          <i class="icon-close remove-icon" @click.stop="removeImage(index, 'web')"></i>
                        </div>
                      </div>
                    </div>

                    <!-- Mobile Image -->
                    <div class="image-wrapper">
                      <div class="image-title">Mobile Image</div>
                      <input type="file" @change="uploadFile($event, index, 'mobile')" style="display: none" :ref="'file_mobile_' + index" />
                      <div v-if="!item.MobileImageUrl" class="image upload-button" @click="uploadFiles(index, 'mobile')">
                        <i class="icon-camera-2"></i>
                        <div>Fotoğraf Yükle</div>
                      </div>
                      <div v-else class="image image-preview" :style="{ backgroundImage: 'url(' + item.MobileImageUrl + ')' }">
                        <div class="image-overlay">
                          <i class="icon-close remove-icon" @click.stop="removeImage(index, 'mobile')"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <el-input type="text" :rows="4" placeholder="Başlık..." v-model="HomeInformationCards[index].Title"> </el-input>
                  <el-input type="textarea" style="margin-top:2px" :rows="4" placeholder="İçerik..." v-model="HomeInformationCards[index].Description"> </el-input>
                </div>

                <!-- Preview Card -->
                <div class="preview-card">
                  <div class="preview-header">
                    <div class="preview-title">Önizleme</div>
                    <div class="delete-icon" @click="deleteItem(index)">
                      <i class="icon-delete-fixed"></i>
                    </div>
                  </div>
                  <div class="card-preview">
                    <div class="card-image" :style="{ backgroundImage: 'url(' + (item.ImageUrl || item.MobileImageUrl) + ')' }"></div>
                    <div class="card-content">
                      <h3 class="card-title">{{ item.Title || 'Başlık' }}</h3>
                      <p class="card-description">{{ item.Description || 'Açıklama' }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="button-group-admin">
                <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
                <el-button class="confirmReportAdmin" type="primary" @click="addItem"><i class="icon-plus"></i>Madde Ekle</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: "0", // Default olarak ilk tab seçili
      languageList: [],
      ValidationErrors: [],
      HomeInformationCards: [],
      commentTypes: [
        { ID: 0, Name: "Doktor" },
        { ID: 1, Name: "Psikolog" },
        { ID: 2, Name: "Diyetisyen" },
        { ID: 3, Name: "İlaç" },
      ],
    };
  },
  async beforeMount() {
    await this.getData();
  },
  methods: {
    async getData() {
      var res = await this.$client.post("/AboutUs/GetHomeInformationCards", {
        Type: parseInt(this.activeTab)
      });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.HomeInformationCards = res.data.Data.Items;
      }
    },
    handleTabClick() {
      this.getData();
    },
    addItem() {
      this.HomeInformationCards.push({
        ID: null,
        Title: null,
        Description: null,
        ImageUrl: null,
        ImageS3FileItemId: null,
        MobileImageUrl: null,
        MobileImageS3FileItemId: null,
        Type: parseInt(this.activeTab), // Aktif tab'e göre tip atanıyor
      });
    },
    deleteItem(index) {
      this.HomeInformationCards.splice(index, 1);
    },
    async update() {
      var payload = { Items : this.HomeInformationCards };
      var res = await this.$client.post("/AboutUs/UpdateHomeInformationCards", payload);
      this.ValidationErrors = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.getData();
      }
    },
    async uploadFile(event, index, type) {
      let file = event.target.files[0];
      var fd = new FormData();
      fd.append("file", file);
      var res = await this.$client.post("/Account/UploadFile?type=3&RelatedItemId=" + this.HomeInformationCards[index].ID, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Upload Response:", res);
      console.log("Response Data:", res.data);
      if (res.data.HasError != undefined && res.data.HasError == true) {
        this.$message.error(res.data.Message);
      } else {
        const updatedCards = [...this.HomeInformationCards];
        if (type === 'web') {
          updatedCards[index].ImageUrl = res.data.Item.Url;
          updatedCards[index].ImageS3FileItemId = res.data.Item.ID;
        } else {
          updatedCards[index].MobileImageUrl = res.data.Item.Url;
          updatedCards[index].MobileImageS3FileItemId = res.data.Item.ID;
        }
        this.HomeInformationCards = updatedCards;
        this.$forceUpdate();
      }
      this.$refs[`file_${type}_${index}`][0].value = null;
    },
    uploadFiles(index, type) {
      this.$refs[`file_${type}_${index}`][0].click();
    },
    removeImage(index, type) {
      const updatedCards = [...this.HomeInformationCards];
      if (type === 'web') {
        updatedCards[index].ImageUrl = null;
        updatedCards[index].ImageS3FileItemId = null;
      } else {
        updatedCards[index].MobileImageUrl = null;
        updatedCards[index].MobileImageS3FileItemId = null;
      }
      this.HomeInformationCards = updatedCards;
      this.$forceUpdate();
    }
  },
};
</script>

<style lang="less">
#fixed-fields {
  .content-wrapper {
    margin-top: 20px;
  }

  .card-container {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }

  .card-edit-section {
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .preview-card {
    flex: 2;
    
    .preview-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .preview-title {
        font-size: 16px;
        font-weight: 600;
        color: #44566c;
      }

      .delete-icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff4d4f;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s;

        i {
          color: white;
          font-size: 16px;
        }

        &:hover {
          transform: scale(1.05);
          background: #ff7875;
        }
      }
    }

    .card-preview {
      position: relative;
      height: 0;
      padding-bottom: 56.25%; // 16:9 oranı
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

      .card-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-color: #f0f2f5;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.15);
        }
      }

      .card-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;
        text-align: center;
        z-index: 1;

        .card-title {
          font-size: 24px;
          font-weight: 700;
          color: white;
          margin: 0 0 12px 0;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        }

        .card-description {
          font-size: 16px;
          color: white;
          margin: 0;
          line-height: 1.5;
          max-width: 80%;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  .part {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .mid-text {
        font-weight: 500;
        font-size: 16px;
        color: #44566c;
      }

      .delete-icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff4d4f;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          transform: scale(1.1);
          color: #ff7875;
        }
      }
    }

    .images-container {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
    }

    .image-wrapper {
      .image-title {
        font-weight: 500;
        font-size: 14px;
        color: #44566c;
        margin-bottom: 8px;
      }
    }

    .image {
      cursor: pointer;
      width: 240px;
      height: 135px;
      background-position: center;
      background-size: cover !important;
      background: #44566c;
      opacity: 0.6;
      border-radius: 10px;
      box-shadow: 0px 16px 32px rgba(169, 194, 209, 0.55), 0px 8px 16px rgba(169, 194, 209, 0.45);
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      &.upload-button {
        opacity: 0.6;
        div, i {
          color: #fff;
          font-weight: 500;
          font-size: 15px;
          align-self: center;
        }
      }

      &.image-preview {
        opacity: 1;
        .image-overlay {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          display: none;
        }

        &:hover .image-overlay {
          display: block;
          background: rgba(0,0,0,0.15);
          border-top-right-radius: 10px;
        }

        .remove-icon {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ff4d4f;
          font-size: 16px;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            transform: scale(1.1);
            color: #ff7875;
          }
        }
      }
    }
  }
}
</style>
